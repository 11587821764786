<template>
  <div class="home-container">

    <van-nav-bar class="nav-bar">

      <template #title>
        <div class="menu-item-icon van-image" style="height: 40px; margin: 10px 0px 0px;">
          <img :src="$store.getters.getBaseInfo.ico" class="van-image__img">
        </div>
      </template>

      <template #right>
        <van-image @click="toService" style="width: 32px;" src="./img/kefu.png"></van-image>
      </template>
    </van-nav-bar>
    <div class="linear-bg"></div>
    <div class="home-scroll">
      <div class="banner">
        <swiper class="banner_swiper" :options="bannerSwiperOption">
          <swiper-slide v-for="(v,key) in banners" :key="key">
            <van-image class="banner_img" round :src="v.url">
              <template v-slot:loading>
                <van-loading type="circular"/>
              </template>
            </van-image>
          </swiper-slide>
        </swiper>
      </div>
      <div class="notice-bar">
        <van-notice-bar
            class="notice-swipe"
            left-icon="bullhorn-o"
            background="#ffffff"
            color="#7e5678"
            :text="this.notice"
        />
        <div class="linear-gradient"></div>
      </div>
      <div class="hot-game">
        <div class="hot-title-div">
          <div>
            <span>{{ $t("home.recommend") }}</span><i>{{ $t("home.txt1") }}</i>
          </div>
          <!--          <div @click="gotoMenu('/Game')">
                      <span>{{ $t("home.more") }}</span>
                      <van-icon name="arrow" color="#979799" />
                    </div>-->
        </div>
        <!--        <div class="hot-items-div">
                  <van-grid :border = false :column-num="4" :icon-size="20">
                    <van-grid-item @click="toLottery(v.key,v.id)" v-for="(v,key) in gameitem" :key="key">
                      <van-image class="game_item_img" :src="v.ico">
                        <template v-slot:loading>
                          <van-loading type="circular"/>
                        </template>
                      </van-image>
                      <span>{{v.name}}</span>
                    </van-grid-item>
                  </van-grid>
                </div>
              </div>-->
        <div style="display: flex; width: 100%; overflow-y: auto; flex-wrap: nowrap;">
          <div class="gdgride" style="margin-right: 5px;" v-for="(item,key) in datalist1" :key="key">
            <div v-for="(v,k) in item" :key="k"  @click="profile(v.id)">
              <div class="van-image van-image--round" style="width: 100px; height: 100px; overflow: hidden; border-radius: 8px;">
                <img class="van-image__img" :src="v.img_url">
              </div>
              <span><i>{{ $t("home.txt2") }}</i></span>
              <div style="position: absolute; bottom: 10px; text-align: center; color: rgb(255, 255, 255); width: 100%; font-size: 12px;">
                {{v.xuanfei_name}}}
              </div>
            </div>
          </div>

          <div style="width: 23px; padding: 20px 5px 10px; height: 145px; margin-top: 30px; background: rgb(249, 249, 249); color: rgb(153, 153, 153); font-size: 12px;">
            もっと見る
          </div>
        </div>
      </div>
        <van-pull-refresh v-model="isLoading" :pulling-text="$t('msg.下拉即可刷新')"
                          :loosing-text="$t('msg.释放即可刷新')" :loading-text="$t('msg.加载中')" @refresh="onRefresh">
          <div class="hot-recommend">
            <div class="hot-title-div">
              <div>
                <span>{{ $t("home.hot") }}</span>
              </div>

            </div>
            <div class="movie_list_1">
              <div class="movie-list-item"  v-for="(v,key1) in datalist" :key="key1" @click="profile(v.id)">
                <div class="horizontalItem">
                  <div class="imgBox">
                    <div class="van-image"
                         style="width: 120px; height: 120px; overflow: hidden; border-radius: 8px;">

                      <img :src="v.img_url" class="van-image__img"
                           style="object-fit: cover;">
                    </div>
                    <span><i >{{$t("profile.fd")}}</i></span>
                  </div>
                  <div style="width: calc(100% - 140px);">
                    <div class="top">
                      <div>
                        <div class="van-image" style="width: 13px;">
                          <img src="img/home/vip.png"  class="van-image__img"></div>
                        <span>{{$t("choose.txt1")}}</span></div>
                      <div>
                        <div class="van-image" style="width: 13px;">
                          <img src="img/home/vip.png" class="van-image__img">
                        </div>
                        <span>{{$t("choose.txt2")}}</span></div>
                      <div> {{ v.xuanfei_name }}</div>
                    </div>
                    <div class="tags">

                      <span  v-for="(item,keys) in v.flag" :key="keys" :class="{'van-tag--large van-tag van-tag--default':1,'van-tag--large':keys==0,'van-tag--success':keys==1,'van-tag--danger':keys==2,'van-tag--warning':keys==3}" >{{item}}</span>

                    </div>
                    <div style="display: flex; align-items: center;">
                      <span style="font-size: 14px; color: rgb(102, 102, 102);">{{$t("choose.txt4")}}：</span>

                      <div tabindex="0" role="radiogroup" class="van-rate van-rate--readonly">
                        <div role="radio" tabindex="0" aria-setsize="5" aria-posinset="1" aria-checked="true" class="van-rate__item">
                          <i data-score="1" class="van-icon van-icon-star van-rate__icon van-rate__icon--full" :style="v.number >= 1?'color: rgb(242, 178, 71); font-size: 15px;':'color: #c8c9cc; font-size: 15px;'"></i>
                        </div>
                        <div role="radio" tabindex="0" aria-setsize="5" aria-posinset="2" aria-checked="true" class="van-rate__item">
                          <i data-score="2" class="van-icon van-icon-star van-rate__icon van-rate__icon--full" :style="v.number >= 2?'color: rgb(242, 178, 71); font-size: 15px;':'color: #c8c9cc; font-size: 15px;'"></i>
                        </div>
                        <div role="radio" tabindex="0" aria-setsize="5" aria-posinset="3" aria-checked="true" class="van-rate__item">
                          <i data-score="3" class="van-icon van-icon-star van-rate__icon van-rate__icon--full" :style="v.number >= 3?'color: rgb(242, 178, 71); font-size: 15px;':'color: #c8c9cc; font-size: 15px;'"></i>
                        </div>
                        <div role="radio" tabindex="0" aria-setsize="5" aria-posinset="4" aria-checked="true" class="van-rate__item">
                          <i data-score="4" class="van-icon van-icon-star van-rate__icon van-rate__icon--full" :style="v.number >= 4?'color: rgb(242, 178, 71); font-size: 15px;':'color: #c8c9cc; font-size: 15px;'"></i>
                        </div>
                        <div role="radio" tabindex="0" aria-setsize="5" aria-posinset="5" aria-checked="true" class="van-rate__item">
                          <i data-score="5" class="van-icon van-icon-star van-rate__icon van-rate__icon--full" :style="v.number >= 5?'color: rgb(242, 178, 71); font-size: 15px;':'color: #c8c9cc; font-size: 15px;'"></i>
                        </div>
                      </div>
<!--                      <div tabindex="0" role="radiogroup" class="van-rate">
                        <div role="radio" tabindex="0" aria-setsize="5" aria-posinset="1"
                             class="van-rate__item" aria-checked="true"><i
                            class="van-icon van-icon-star van-rate__icon van-rate__icon&#45;&#45;full"
                            style="font-size: 18px;"></i></div>
                        <div role="radio" tabindex="0" aria-setsize="5" aria-posinset="2"
                             class="van-rate__item" aria-checked="true"><i
                            class="van-icon van-icon-star van-rate__icon van-rate__icon&#45;&#45;full"
                            style="font-size: 18px;"></i></div>
                        <div role="radio" tabindex="0" aria-setsize="5" aria-posinset="3"
                             class="van-rate__item" aria-checked="true"><i
                            class="van-icon van-icon-star van-rate__icon van-rate__icon&#45;&#45;full"
                            style="font-size: 18px;"></i></div>
                        <div role="radio" tabindex="0" aria-setsize="5" aria-posinset="4"
                             class="van-rate__item" aria-checked="true"><i
                            class="van-icon van-icon-star van-rate__icon van-rate__icon&#45;&#45;full"
                            style="font-size: 18px;"></i></div>
                        <div role="radio" tabindex="0" aria-setsize="5" aria-posinset="5"
                             class="van-rate__item" aria-checked="true"><i
                            class="van-icon van-icon-star van-rate__icon van-rate__icon&#45;&#45;full"
                            style="font-size: 18px;"></i></div>
                      </div>-->
                    </div>
                    <div style="color: rgb(153, 153, 153); font-size: 12px; margin: 5px 0px;">
                      {{$t("profile.height")}}:{{ v.height }} {{ $t("profile.cup") }}：{{v.cup}} {{$t("choose.txt5")}}
                    </div>
                    <div class="yueta">{{$t("choose.txt3")}}</div>
                  </div>
                </div>
              </div>

              <div class="van-empty" style="width: 100%; padding-top: 90px; color: rgb(0, 0, 0);" v-if="datalist.length == 0">
                <div class="van-empty__image" style="width: 50px; height: 50px;">
                  <img src="/img/emptyData.png"></div>
                <p class="van-empty__description">{{ $t('msg.没有更多了') }}</p>
              </div>

            </div>

          </div>
        </van-pull-refresh>
      </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      notice: this.$t("msg.获取中"),
      banners: [{}],
      basicData: [],
      datalist: [],
      datalist1: [
        {}
      ],
      gameitem: [{}, {}, {}, {}],
      movielist_0: [{}, {}, {}, {}],
      movielist_1: [{}, {}, {}, {}, {}, {}, {}, {}],
      isLoading: false,
      movielistSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup: 1,
      },
      bannerSwiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        speed: 800,
        autoplay: true,
        coverflowEffect: {
          rotate: 50,
          stretch: 10,
          depth: 100,
          modifier: 1,
          slideShadows: true
        }
      }
    };
  },
  methods: {

    profile(id) {
      this.$router.push({path: '/profile?id=' + id});
    },
    toService(){
      this.$router.push("ServiceOnline");
    },
    getXuanfeilist() {
      this.$http({
        method: 'get',
        url: 'xuanfeilist',
        data: {id: 0}
      }).then(res => {
        this.datalist = res.data.list;
        this.datalist1 = res.data.list1;
      });
    },
    gotoMenu(router) {
      this.$router.replace(router)
    },
    toLottery(key, id) {
      if (!localStorage.getItem('token')) {
        this.$router.push({path: '/Login'})
      } else {
        this.$router.push({path: '/Lottery?key=' + key + "&id=" + id})
      }

    },
    toPlayVideo(id) {
      if (!localStorage.getItem('token')) {
        this.$router.push({path: '/Login'})
      } else {
        this.$router.push({path: '/PlayVideo?id=' + id})
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.getBasicConfig();
        this.isLoading = false;
        this.$toast(this.$t("msg.刷新成功！"));
      }, 500);
    },
    getBasicConfig() {
      this.$http({
        method: 'get',
        url: 'sys_config'
      }).then(res => {
        this.basicData = res.data;
        this.getNotice(this.basicData);//获取公告
        this.getBanner(this.basicData);//获取banner
        this.getGameItem();//获取首页游戏列表
        this.getMovieList_0(this.basicData);//获取首页视频0
        this.getMovieList_1(this.basicData);//获取首页视频1
      })

    },
    getNotice(data) {
      this.notice = data.notice;
    },
    getGameItem() {
      this.$http({
        method: 'get',
        url: 'lottery_hot'
      }).then(res => {
        this.gameitem = res.data
      })
    },
    getMovieList_0(data) {
      this.movielist_0 = data.movielist_0
    },
    getMovieList_1(data) {
      this.movielist_1 = data.movielist_1
    },
    getBanner(data) {
      this.banners = data.banners;
    }
  },
  mounted() {

  },
  created() {
    this.getBasicConfig();
    this.getXuanfeilist();
  }
}

</script>

<style lang='less' scoped>
@notice-bar-size: 30px;
@movie-list-item-bottom-size: 25px;
.linear-bg {
  height: 200px;
  background: linear-gradient(270deg, #e6c3a1, #7e5678);
}

.home-container {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
}

.linear-gradient {
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));
}

::v-deep .van-notice-bar__left-icon, .van-notice-bar__right-icon {
  min-width: 40px;
}

.notice-swipe {
  width: calc(100% - 50px);
  height: 85px;
  font-size: @notice-bar-size;
}

::v-deep .van-icon-bullhorn-o::before {
  transform: scale(2.5);
}

.banner {
  width: 100%;
  margin-top: -23%;
}

.banner_swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 620px;
    height: 300px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    color: #ffffff;
  }
}

::v-deep .swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

::v-deep .swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

.banner_img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.hot-game {
  width: 100%;
  height: 100%;
}

.hot-title-div {
  width: calc(100% - 50px);
  margin: 0 auto;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hot-title-div > div:first-child {
  /*width: 130px;*/
}

.hot-title-div div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.hot-title-div > div:nth-child(2) span {
  font-size: 20px;
  color: #979799;
}

.hot-title-div > div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  font-weight: 700;
  color: #000;
}

.hot-title-div > div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 28px;
  font-weight: 700;
  color: #000;
}

.hot-title-div > div:nth-child(2) span {
  font-size: 25px;
  color: #979799;
}

.hot-title-div > div:first-child span:before {
  content: "";
  display: block;
  width: 5px;
  height: 30px;
  background-color: #7e5678;
  border-radius: 1px;
  margin-right: 5px;
}

.hot-game .hot-items-div {
  margin-top: -3px;
}

.hot-game .hot-items-div span {
  margin-top: 10px;
  font-size: 28px;
  color: #000;
}

.hot-recommend {
  width: 100%;
  flex: 1;
  background-color: #f2f2f5;
}

.movie_swiper {
  .swiper-slide {
    width: 80%;
  }
}

.movie_list_0 {
  width: calc(100% - 50px);
  margin: 0 auto;
}

.movie_cover {
  border-radius: 10px;
  width: 550px;
  height: 330px
}

.movie_list_0 .movie-list-item-bottom {
  position: relative;
  width: 550px;
  bottom: 43px;
}

.movie_list_0 .movie-list-item-bottom .movie-time-div {
  background-color: rgba(0, 0, 0, .4);
}

.movie_list_0 .movie-list-item-bottom > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
  font-size: 28px;
  color: #fff;
}

.movie_list_0 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
}

.movie_list_0 .movie_swiper .hot {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 80px;
}

.movie_list_0 span {
  font-size: 30px;
}

.movie_list_1 {
  display: flex;
  width: calc(100% - 50px);
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.movie_list_1 .movie-list-item .cover_img {
  border-radius: 10px;
  width: 335px;
  height: 290px;
}

.home-scroll {
  padding-bottom: 110px;
}

.movie_list_1 .movie-list-item {
  margin-bottom: -10px;
}

.movie_list_1 .movie-list-item-bottom {
  position: relative;
  width: 335px;
  bottom: 42px;

}

.movie_list_1 .movie-list-item:nth-child(odd) {
  margin-right: 25px;
}

.movie_list_1 .movie-list-item-bottom .movie-time-div {
  background-color: rgba(0, 0, 0, .4);
}

.movie_list_1 .movie-list-item-bottom > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}

.movie_list_1 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
  height: 35px;
}

.movie_list_1 .movie_swiper .hot {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 5px;
}

.movie_list_1 .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
  padding-left: 8px;
  font-size: 25px;
}

.movie_list_1 .movie-list-item .movie-time-div span:last-child {
  overflow: hidden;
  white-space: nowrap;
  width: 0px;
  padding-right: 110px;
  font-size: 22px;
}

.movie_list_0 .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 350px;
  padding-left: 10px;
  font-size: 25px;
}

.hot-recommend-more {
  width: 100%;
  padding-bottom: 20px;
  text-align: center;
  color: #979799;
  font-size: 30px;
}

.hot-items-div .game_item_img {
  width: 100px;
  height: 100px;
}

::v-deep .hot-items-div .game_item_img .van-image__img {
  border-radius: 20px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}

.hot-title-div > div:first-child i {
  background: #3f3a5b;
  padding: .533vw;
  color: #ebcaaf;
  font-size: 2.933vw;
  border-radius: 1.067vw 0;
}
.gdgride {
  padding-right: 5.333vw;
}
.gdgride>div {
  position: relative;
  overflow: hidden;
  border-radius: 1.067vw;
}
.gdgride>div>span{
  position: absolute;
  z-index: 9;
  background-color: #ebcaaf;
  color: #8d684b;
  transform: rotate(45deg);
  width: 20vw;
  height: 20vw;
  font-size: 3vw;
  top: -11vw;
  right: -11vw;
}
.gdgride>div>span>i {
  position: absolute;
  bottom: .667vw;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 700;
}

.horizontalItem {
  display: flex;
  border-bottom: 1px solid #eee;
  padding: 2.667vw 0 4vw;
}
.horizontalItem .imgBox{
  position: relative;
  overflow: hidden;
  border-radius: 1.067vw;
  margin-right: 2.667vw;
}
.horizontalItem .top {
  display: flex;
}

.horizontalItem .top>div:first-child, .horizontalItem .top>div:nth-child(2) {
  background: linear-gradient(180deg, #e7caaf, #fff7ed);
  color: #a4826b;
  font-weight: 600;
  font-size: 3vw;
  padding: .4vw;
  margin-right: 1.333vw;
}
.horizontalItem .tags {
  display: flex;
  margin: .667vw 0 .667vw;
  flex-wrap: wrap;
}
.horizontalItem .tags>.van-tag {
  padding: .667vw;
  font-size: 3.333vw;
  margin: 0 .667vw .667vw 0;
  border-radius: 1.067vw;
  line-height: 4vw;
}
.horizontalItem .yueta {
  background: linear-gradient(90deg, #df35ad, #4f1db5);
  color: #fff;
  border-radius: 1.067vw;
  padding: 1.333vw 0;
  text-align: center;
  font-size: 4vw;
}
.horizontalItem .imgBox>span {
  position: absolute;
  z-index: 9;
  background-color: #ebcaaf;
  color: #8d684b;
  transform: rotate(45deg);
  width: 20vw;
  height: 20vw;
  top: -11vw;
  right: -11vw;
}
.horizontalItem .imgBox>span>i {
  position: absolute;
  bottom: .667vw;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 700;
  font-size: 4vw;
}
</style>
