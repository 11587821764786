<template>
	<div class="page">
		<van-nav-bar :title="xuanfeidata.xuanfei_name" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>

    <div  class="feiziInfo">
      <div class="topInfo">
        <div >
          <span >{{ xuanfeidata.xuanfei_name }}</span>
        </div>
        <div >{{$t("profile.height")}}:{{ xuanfeidata.height }} {{ $t("profile.cup") }}：{{xuanfeidata.cup}}</div>
        <div>
          <div v-for="(item,keys) in xuanfeidata.flag" :key="keys">{{item}}</div>

        </div>
        <div >
          <span  style="color: rgb(107, 34, 179); font-size: 14px;">{{$t("choose.txt4")}}：</span>
          <div tabindex="0" role="radiogroup" class="van-rate van-rate--readonly">
            <div role="radio" tabindex="0" aria-setsize="5" aria-posinset="1" aria-checked="true" class="van-rate__item">
              <i data-score="1" class="van-icon van-icon-star van-rate__icon van-rate__icon--full" :style="xuanfeidata.number >= 1?'color: rgb(242, 178, 71); font-size: 15px;':'color: #c8c9cc; font-size: 15px;'"></i>
            </div>
            <div role="radio" tabindex="0" aria-setsize="5" aria-posinset="2" aria-checked="true" class="van-rate__item">
              <i data-score="2" class="van-icon van-icon-star van-rate__icon van-rate__icon--full" :style="xuanfeidata.number >= 2?'color: rgb(242, 178, 71); font-size: 15px;':'color: #c8c9cc; font-size: 15px;'"></i>
            </div>
            <div role="radio" tabindex="0" aria-setsize="5" aria-posinset="3" aria-checked="true" class="van-rate__item">
              <i data-score="3" class="van-icon van-icon-star van-rate__icon van-rate__icon--full" :style="xuanfeidata.number >= 3?'color: rgb(242, 178, 71); font-size: 15px;':'color: #c8c9cc; font-size: 15px;'"></i>
            </div>
            <div role="radio" tabindex="0" aria-setsize="5" aria-posinset="4" aria-checked="true" class="van-rate__item">
              <i data-score="4" class="van-icon van-icon-star van-rate__icon van-rate__icon--full" :style="xuanfeidata.number >= 4?'color: rgb(242, 178, 71); font-size: 15px;':'color: #c8c9cc; font-size: 15px;'"></i>
            </div>
            <div role="radio" tabindex="0" aria-setsize="5" aria-posinset="5" aria-checked="true" class="van-rate__item">
              <i data-score="5" class="van-icon van-icon-star van-rate__icon van-rate__icon--full" :style="xuanfeidata.number >= 5?'color: rgb(242, 178, 71); font-size: 15px;':'color: #c8c9cc; font-size: 15px;'"></i>
            </div>
          </div>
        </div>
        <div style="color: rgb(107, 34, 179); font-size: 14px; margin: 4px 0px;">{{ $t("profile.area") }}：{{ xuanfeidata.area }} </div>
        <div class="yuyueBox" @click="yuyue">{{$t("profile.liji")}}</div>
        <div class="dianzanimg">
          <div class="van-image" style="width: 60px; height: 60px;" @click="nunfuncti">
            <img src="/img/zan.c28032c8.png" class="van-image__img" style="object-fit: cover;">
          </div>
          <p style="color: rgb(107, 34, 179); margin: 0px;">{{ xuanfeidata.dianzan }}</p>
        </div>
      </div>
      <div class="cenInfo">


        <van-tabs v-model="active" animated swipeable  @change="OnChange"  style="width: 50%;">
          <van-tab v-for="(v,key) in addlist" :key="key" :title="$t(v)" :name="key" ></van-tab>
        </van-tabs>



        <div v-show="active==0">
          <div class="imgswiper">
            <div style="width: 100px; height: 100px; margin-right: 10px;" v-for="(item,keys) in xuanfeidata.img_url" :key="keys" >
              <div :class="keys==atr?'active imgI van-image':'imgI van-image'"  style="width: 100px; height: 100px;" @click="imgskk(item.img,keys)">
                <img :src="item.img" class="van-image__img" style="object-fit: cover;">
              </div>
            </div>

          </div>
          <div class="van-image" style="width: 100%; height: 635px;">
            <img :src="imgs_url" class="van-image__img" style="object-fit: cover;">
          </div>
        </div>
        <div style="padding-top: 20px;" v-show="active==1">

          <video width="98%" fit="contain" height="100%"   :src="xuanfeidata.pvideo" controls="true" controlslist="nodownload"/>
        </div>
      </div>
      <div class="btmInfo">
        <div style="color: rgb(42, 20, 104); margin-bottom: 10px; font-weight: bold;">{{$t("profile.jianjie")}}</div>
        <div style="color: rgb(83, 69, 137);">{{xuanfeidata.jianjie}}</div>
      </div>
    </div>










	</div>
</template>

<script>
import videojs from "video.js";

export default {
	data() {
		return {
      vod_name:"",
      imgs_url:"",
			xuanfeidata: [],
      atr:0,
      active:0,
      addlist:[
        'profile.portrait',
        'profile.video'
      ],
      nowPlayVideoUrl:""
		};
	},
	methods: {
    nunfuncti(){
        ++this.xuanfeidata.dianzan;
    },
    OnChange(){

    },
    imgskk(img,key){
      this.imgs_url = img;
      this.atr = key;
    },
    lanfun(type){
      this.actives = type
      console.log(this.actives);
    },
		back() {
      return window.history.back();
		},
		getxuanfeidata() {
			this.$http({
				method: 'get',
				url: 'xuanfeidata',
				data: { id: this.$route.query.id }
			}).then(res => {
				this.xuanfeidata = res.data;
        this.imgs_url = res.data.img_url[0].img;

        this.nowPlayVideoUrl = this.xuanfeidata.pvideo;
        this.isMp4 = 1;
        this.cover = "";
        let videos = document.getElementById('my-video');
        videos.poster = this.cover;
        this.getVideo();

			});
		},

    getVideo() {
      this.player = videojs("my-video",  {
        height:"200px",
        width:"100%",
        preload: "auto", // 预加载
        controls: false,  // 显示播放的控件
        multipleArray: [0.75, 1, 1.5, 2], // 倍速设置
      });
      this.type = "application/x-mpegURL";
      if(this.isMp4 == 1){
        this.type = "";
      }
      this.player.src([{
        src: this.nowPlayVideoUrl,
        type: this.type // 告诉videojs,这是一个hls流
      }]);

    },
		yuyue() {
      this.$router.push("JumpPage");
			//this.$toast(this.$t("msg.请联系顾问或接待员"));
		}
	},
	created() {
    this.vod_name = this.$route.query.name;
		this.getxuanfeidata();
	}
};
</script>

<style>
.van-tab--active{
  border-bottom: 5px #ee0a24 solid;
}
.feiziInfo .cenInfo .imgswiper {
  width: 100%;
  overflow-x: scroll;
  margin: 4vw 0;
  display: flex;
  flex-wrap: nowrap;
}
.van-tab--active {
  color: #323233;
  font-weight: 500;
}
.van-tab {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 4px;
  color: #646566;
  font-size: 20px;
  line-height: 20px;
  cursor: pointer;
}
.feiziInfo>div {
  padding: 4vw 20px;
  background: #fff;
  margin-bottom: 4vw;
}
.feiziInfo .topInfo .yuyueBox {
  position: absolute;
  right: 4vw;
  bottom: 20px;
  color: #fff;
  padding: 10px 15px;
  background: linear-gradient(20deg,#e73266,#ee5380);
  border-radius: 7vw;
}
.feiziInfo .topInfo .dianzanimg {
  position: absolute;
  top: 4vw;
  right: 7vw;
  text-align: center;
}
.feiziInfo .topInfo>div:nth-child(3) {
  width: 50%;
}
.feiziInfo .topInfo>div:nth-child(3)>div {
  background: linear-gradient(50deg,#9b54ca,#e6557f);
  color: #fff;
  display: inline-block;
  border-radius: 10px;
  margin-right: 15px;
  font-size: 16px;
  padding: 15px;
  margin-bottom: 15px;
}
.feiziInfo .topInfo>div:first-child {
  font-weight: 600;
}
.feiziInfo .topInfo>div:nth-child(2) {
  color: #6b22b3;
  font-size: 3vw;
  margin: 10px 0;
}
.feiziInfo .topInfo>div:first-child>span:first-child {
  color: #2c1868;
  font-size: 5vw;
}
.feiziInfo>div {
  padding: 4vw 20px;
  background: #fff;
  margin-bottom: 4vw;
}
.feiziInfo .topInfo {
  position: relative;
}
.feiziInfo {
  background: #ebedf0;
}
.list h3{
  text-align: left;
  margin-left: 10px;
}
.div_list{
  width: 25%;
  float: left;
  margin: 10px 0;
}
.div_list div:nth-child(2){
    color: #999;
}

</style>
