const Zh = {
    login: {
        title: "登录",
        username: "请输入用户名",
        password: "请输入密码",
        forgetPaw: "忘记密码",
        registerNow: "没有账户？马上注册",
        doLogin: "登录",

    },
    register:{
        title: "注册",
        username: "请输入用户名(6-12个英文字母或数字)",
        password: "请输入登录密码(6-12个英文字母或数字)",
        code: "请输入邀请码",
        agreement: "我已经知晓并同意《开户协议》各项条约",
        chooseAgreement: "请勾选下方开户协议！",
        doRegister: "注册",
    },
    footer:{
        home : "首页",
        game: "预约",
        video: "视频",
        mine: "我的",
    },
    game:{
        title: "预约大厅",
        all: "全部",
    },
    choose:{
        title: "选妃",
        city: "城市大全",
        rule: "本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。",
        price: "价格流程",
        resource: "有哪些资源?",
        character: "网红、模特、空姐、嫩模、大学生，只有您想不到，没有本平台办不到",
        scope: "服务范围?",
        location: "同城免费约炮，任意地点全国空降，国内一二线城市当地均有，三线城市也可通过联系接待员预约安排。",
    },
    home:{
        recommend: "推荐任务",
        more: "查看更多",
        popularity: "人气排行",
        hot: "热门推荐",
    },
    lottery:{
        jieguo: "投票結果",
        jiesu: "结束",
        record: "任务记录",
        rule: "玩法规则",
        prompt: "玩法提示",
        gameplay: "从可选和值形态里面选择号码进行下注",
        explain: "中奖说明",
        wprole: "三个开奖号码总和值11~18 为大;总和值3~ 10为小;",
        example: "投注范例",
        option: "投注方案: 小 开奖号码: 123,即中小",
        task: "任务单",
        money: "可用余额",
        submit: "提交",
        selection: "当前选号",
        perNote: "每注金额",
        inputMoney: "请输入金额",
        total: "总共",
        note: "总共",
        statistics: "合计",
        empty: "清空订单",
        verify: "确认提交",
        issue: "期号",
        num: "开奖号码",
        big: "大",
        small: "小",
        single: "单",
        double: "双",
        unselected: "未选择",
        drawLottery: "开奖成功，期号: ",
        kefu: "请联系管理员领取该任务!",
    },
    mine:{
        recharge: "充值",
        withdraw: "提现",
        myMoney: "我的钱包",
        detail: "详情",
        balance: "余额",
        finance: "个人报表",
        order: "账户明细",
        record: "任务记录",
        my: "个人中心",
        notice: "信息公告",
        service: "在线客服",
        score: "积分",
    },
    setting:{
        title: "设置",
        basic: "基本信息设置",
        password: "登录密码",
        capital: "资金密码",
        lang: "语言",
        quit: "退出登录",
    },
    setPayPassword: {
        title: "设置资金密码",
        opw: "请输入资金密码",
        tpw: "请再次输入资金密码",
    },
    gameRecord:{
        title: "任务记录",
        amountBet: "下注金额",
        create: "下单时间",
        update: "结算时间",
    },
    video:{
        title: "视频影院",
        play: "播放",
    },
    playVideo:{
        play: "次播放",
    },
    setname:{
        title: "修改真实姓名",
        name: "姓名",
        truename: "请输入真实姓名",
        hint: "为了您账户安全,真实姓名需要与绑定银行卡姓名一致",
    },
    setsex:{
        title: "性别修改",
    },
    bindCard:{
        title: "填写收款卡",
        msg: "请输入您的收款卡信息",
        hint: "尊敬的用户,为了保障您的资金安全,请您绑定您的真实姓名和设置取款密码,如果姓名与开户名不一致,将无法取款",
        card: "帐号（普通）",
        truecard: "输入帐号（普通）",
        name: "帐户姓名（真名）",
        truename: "输入帐户姓名（真名）",
        financial_institution: "金融机构名称",
        truefinancial_institution: "输入金融机构名称",
        branch: "分行名",
        truebranch: "输入分行名称",
        branch_number: "分行编号",
        truebranch_number: "输入分行编号"
    },
    setbank:{
        title: "收款卡信息",
        bank: "添加银行卡",
        hint: "提示:请绑定大型商业银行,如需修改,请您联系在线客服",
    },
    setLoginPassword:{
        title: "修改登录密码",
        old_password: "旧密码",
        old_password_p: "请输入旧密码",
        o_new_password: "新密码",
        o_new_password_p: "请输入新密码",
        t_new_password_p: "请再次输入新密码",
    },
    infomation:{
        title: "基本信息",
        head: "头像",
        select: "选择头像",
        name: "真实姓名",
        sex: "性别",
        man: "男",
        girl: "女",
        unknown: "未知",
        binding: "绑定信息",
    },
    withdraw: {
        title: "提现中心",
        record: "提现记录",
        money: "提现金额",
        all: "全部",
        min: "单笔限额: 最低",
        max: "最高",
        num: "提现次数: 一天最高提现",
        hint: "到账时间: 一般到账时间在5分钟左右，最快2分钟内到账",
        quota: "限额说明",
        balance: "余额",
        withdraw: "马上提现",
    },
    withdrawRecord:{
        desc: "说明",
        create: "提交时间",
        update: "审核时间",
    },
    personalreport:{
        title: "个人报表",
        profit: "盈利金额",
        formula: "盈利计算公式 : 中奖金额 - 任务金额",
        task: "任务金额",
        recharge: "充值金额",
        withdrawal: "提现金额",
        win_money: "中奖金额",
    },
    service: {
        title: "在线客服",
        relation: "联系",
        hint: "全天7 * 24小时竭诚为您服务",
    },
    profile: {
        portrait: "写真",
        video: "视频",
        jianjie: "个人简介",
        liji: "立即预约",
        chongdan: "充电范围",
        fd: "贵妇",
        yuyue: "预约",

        archives: "妹子档案",
        age: "年龄",
        height: "身高",
        weight: "体重",
        cup: "罩杯",
        city: "城市",
        area: "服务区域",
        item: "服务项目"
    },
    msg:{
        "次": "次",
        "用户不存在！": "用户不存在！",
        "密码错误！": "密码错误！",
        "登录成功！": "登录成功！",
        "邀请码不存在！": "邀请码不存在！",
        "用户名位数错误！": "用户名位数错误！",
        "密码位数错误！": "密码位数错误！",
        "用户名不能存在中文！": "用户名不能存在中文！",
        "邀请码不能为空！": "邀请码不能为空！",
        "注册成功！": "注册成功！",
        "注册失败！": "注册失败！",
        "用户已存在": "用户已存在",
        "刷新成功！": "刷新成功！",
        "请联系顾问或接待员": "请联系顾问或接待员",
        "金额错误！": "金额错误！",
        "请选号！": "请选号！",
        "请填写金额!": "请填写金额!",
        "余额不足，请联系客服充值！": "余额不足，请联系客服充值！",
        "没有更多了": "没有更多了",
        "没有数据": "没有数据",
        "余额不足！": "余额不足！",
        "扣款失败！": "扣款失败！",
        "下注异常！": "下注异常！",
        "投注成功！": "投注成功！",
        "参数异常！": "参数异常！",
        "获取中": "获取中 …",
        "请完成任务单后进入": "请完成任务单后进入",
        "请联系客服充值": "请联系客服充值",
        "请设置收款卡!": "请设置收款卡!",
        "功能已禁用!": "功能已禁用!",
        "账号下线": "账号下线",
        "登录/注册": "登录/注册",
        "登录可享受更多服务！": "登录可享受更多服务！",
        "未设置": "未设置",
        "已设置": "已设置",
        "提交": "提交",
        "确定": "确定",
        "请填写完整": "请填写完整",
        "两次密码不一致！": "两次密码不一致！",
        "设置提现密码成功！": "设置提现密码成功！",
        "提现密码已设置，需要修改请联系客服": "提现密码已设置，需要修改请联系客服",
        "已绑定": "已绑定",
        "无": "无",
        "更换头像成功！": "T更换头像成功！",
        "更新头像失败！": "更新头像失败！",
        "请勿重复设置！": "请勿重复设置！",
        "设置姓名成功！": "设置姓名成功！",
        "设置姓名失败！": "设置姓名失败！",
        "设置性别成功！": "设置性别成功！",
        "设置性别失败！": "设置性别失败！",
        "请设置姓名后再绑定银行卡！": "请设置姓名后再绑定银行卡！",
        "请设置提现密码后再绑定银行卡！": "请设置提现密码后再绑定银行卡！",
        "确认绑卡": "确认绑卡",
        "请输入银行卡号！": "请输入银行卡号！",
        "请输入银行名称！": "请输入银行名称！",
        "设置银行卡成功！": "设置银行卡成功！",
        "设置银行卡失败！": "设置银行卡失败！",
        "旧密码错误": "旧密码错误",
        "修改密码成功！": "修改密码成功！",
        "修改密码失败！": "修改密码失败！",
        "请填写正确的金额": "请填写正确的金额",
        "提现金额不能少于: ": "提现金额不能少于: ",
        "提现金额不能大于: ": "提现金额不能大于: ",
        "当日提现次数已用完": "当日提现次数已用完",
        "提现成功！": "提现成功！",
        "提现失败！": "提现失败！",
        "金额": "金额",
        "待审核": "待审核",
        "审核成功": "审核成功",
        "审核退回": "审核退回",
        "请充值后观看视频！": "请充值后观看视频！",
        "释放即可刷新": "释放即可刷新...",
        "下拉即可刷新": "下拉即可刷新...",
        "加载中": "加载中...",
    },
    setLang:{
        title: "设置语言"
    },
    lang:{
        zh: "中文 ",
        jp: "日本 ",
        ara: "阿拉伯语 ",
        en: "英语 ",
        spa: "西班牙语 ",
        it: "意大利语 ",
        de: "德语 ",
        pt: "葡萄牙语 ",
        fra: "法语",
        nl: "荷兰语",
        id: "印尼语",
        hi: "印地语",
        kor: "韩语",
        tr: "土耳其语",
        swe: "瑞典语",
        ga: "爱尔兰语",
        ru: "俄语",
        vie: "越南语",
        th: "泰语",
        est: "爱沙尼亚语",
        be: "白俄罗斯语",
        bul: "保加利亚语",
        pl: "波兰语",
        ms: "马来语",
    }
}
export default  Zh