const Id = {
    login: {
        title: "Log masuk",
        username: "Silakan masukkan nama pengguna",
        password: "Silakan masukkan password",
        forgetPaw: "Lupa sandi",
        registerNow: "Tidak ada akun? Daftar sekarang.",
        doLogin: "Log masuk",

    },
    register:{
        title: "Pendaftaran",
        username: "Silakan masukkan nama pengguna (6-12 huruf atau angka bahasa Inggris)",
        password: "Silakan masukkan kata sandi login Anda (6-12 huruf atau angka bahasa Inggris)",
        code: "Silakan masukkan kode undangan",
        agreement: "Saya sudah tahu dan menyetujui perjanjian Perjanjian Pembukaan Akun.",
        chooseAgreement: "Silakan periksa perjanjian pembukaan akun di bawah ini!",
        doRegister: "Pendaftaran",

    },
    footer:{
        home : "Laman Rumah",
        game: "Janji temu",
        video: "Video",
        mine: "Milikku.",

    },
    game:{
        title: "Aula reservasi",
        all: "Semua",

    },
    choose:{
        title: "Pilih saja.",
        city: "Kota Daquan",
        rule: "Platform ini adalah yang paling otentik di seluruh jaringan + pengawalan bisnis + gairah kota yang sama. Untuk memastikan privasi pribadi setiap pengguna, pelanggan hanya dapat bergabung dengan menghubungi resepsionis atau rekomendasi nama asli anggota senior platform.",
        price: "Proses harga",
        resource: "Sumber daya apa yang ada?",
        character: "Merah bersih, model, pramugari, model lembut, mahasiswa, hanya Anda yang tidak bisa memikirkannya, tidak ada ini",
        scope: "Lingkup layanan?",
        location: "Senjata gratis di kota yang sama, di mana saja di udara nasional, ada kota-kota tingkat pertama dan kedua di Cina, dan kota-kota tingkat ketiga juga dapat membuat janji dengan menghubungi resepsionis.",

    },
    home:{
        recommend: "Tugas yang Direkomendasikan",
        more: "Lihat Lebih Lanjut",
        popularity: "Peringkat popularitas",
        hot: "Rekomendasi populer",

    },
    lottery:{
        jieguo: "Hasil pemungutan suara",
        jiesu: "Akhir dari",
        record: "Catatan tugas",
        rule: "Aturan bermain",
        prompt: "Tips untuk Bermain",
        gameplay: "Pilih nomor dari opsional dan bentuk nilai untuk bertaruh",
        explain: "Instruksi Menang",
        wprole: "Nilai total dari tiga nomor undian adalah 11 hingga 18; Nilai jumlah 3 hingga 10 kecil;",
        example: "Contoh taruhan",
        option: "Rencana taruhan: nomor undian kecil: 123, yaitu kecil dan menengah",
        task: "Daftar Tugas",
        money: "Saldo yang tersedia",
        submit: "Commit",
        selection: "Nomor pemilihan saat ini",
        perNote: "Jumlah per catatan",
        inputMoney: "Silakan masukkan jumlah",
        total: "Total",
        note: "Total",
        statistics: "Total",
        empty: "Kosongkan perintah.",
        verify: "Konfirmasi pengajuan",
        issue: "Nomor Masalah",
        num: "Nomor undian",
        big: "Besar",
        small: "Kecil",
        single: "Single",
        double: "Double",
        unselected: "Tak ada yang dipilih",
        drawLottery: "Undian berhasil, nomor masalah:",
        kefu: "Silakan hubungi administrator untuk tugas ini!",

    },
    mine:{
        recharge: "Isi Ulang",
        withdraw: "Cash Up",
        myMoney: "Dompet saya",
        detail: "Rincian",
        balance: "Saldo",
        finance: "Laporan pribadi",
        order: "Rincian akun",
        record: "Catatan tugas",
        my: "Personal center",
        notice: "Pengumuman informasi",
        service: "Layanan pelanggan online",
        score: "Poin",
    },
    setting:{
        title: "Pengaturan",
        basic: "Pengaturan Informasi Dasar",
        password: "Kata sandi untuk masuk",
        capital: "Kata sandi dana",
        lang: "Bahasa",
        quit: "Keluar dari Log Masuk",

    },
    setPayPassword: {
        title: "Tetapkan kata sandi dana",
        opw: "Silakan masukkan kata sandi dana",
        tpw: "Silakan masukkan kata sandi dana lagi",

    },
    gameRecord:{
        title: "Catatan tugas",
        amountBet: "Jumlah taruhan",
        create: "Waktu untuk melakukan pemesanan",
        update: "Waktu penyelesaian",

    },
    video:{
        title: "Bioskop video",
        play: "Mainkan saja.",

    },
    playVideo:{
        play: "Second Play",

    },
    setname:{
        title: "Ubah nama asli",
        name: "Nama",
        truename: "Silakan masukkan nama asli Anda",
        hint: "Untuk keamanan akun Anda, nama asli harus sama dengan nama kartu bank yang terikat.",

    },
    setsex:{
        title: "Modifikasi gender",

    },
    bindCard:{
        title: "Isi kartu tanda terima",
        msg: "Silakan masukkan informasi kartu tanda terima Anda",
        hint: "Pengguna yang terhormat, untuk melindungi keamanan dana Anda, harap ikat nama asli Anda dan atur kata sandi penarikan. Jika nama tidak konsisten dengan nama akun, Anda tidak akan dapat menarik uang.",
        card: "Nomor akun (normal)",
        truecard: "Masukkan nomor akun (normal)",
        name: "Nama akun (nama asli)",
        truename: "Masukkan nama akun (nama asli)",
        financial_institution: "Nama lembaga keuangan",
        truefinancial_institution: "Masukkan nama lembaga keuangan",
        branch: "Nama Cabang",
        truebranch: "Masukkan nama branch",
        branch_number: "Nomor Cabang",
        truebranch_number: "Masukkan nomor cabang"
    },
    setbank:{
        title: "Informasi kartu tanda terima",
        bank: "Tambah kartu bank",
        hint: "Kiat: Silakan ikat bank komersial besar. Jika Anda perlu memodifikasi, silakan hubungi layanan pelanggan online.",

    },
    setLoginPassword:{
        title: "Ubah kata sandi login",
        old_password: "Kata sandi lama",
        old_password_p: "Silakan masukkan kata sandi lama",
        o_new_password: "Kata sandi baru",
        o_new_password_p: "Silakan masukkan kata sandi baru",
        t_new_password_p: "Silakan masukkan kata sandi baru lagi",

    },
    infomation:{
        title: "Informasi Dasar",
        head: "Avatar",
        select: "Pilih avatar",
        name: "Nama asli",
        sex: "Gender",
        man: "Pria",
        girl: "Wanita",
        unknown: "Tidak diketahui",
        binding: "Mengikat informasi",

    },
    withdraw: {
        title: "Cash Cash Center",
        record: "Catatan penarikan uang tunai",
        money: "Jumlah penarikan tunai",
        all: "Semua",
        min: "Batas tunggal: minimum",
        max: "",
        num: "Penarikan tunai: penarikan tunai satu hari",
        hint: "Waktu kedatangan: Waktu kedatangan umum adalah sekitar 5 menit, dan yang tercepat adalah 2 menit.",
        quota: "Deskripsi batas",
        balance: "Saldo",
        withdraw: "Tarik tunai sekarang",

    },
    withdrawRecord:{
        desc: "Penjelasan",
        create: "Waktu Pengiriman",
        update: "Waktu Ulasan",

    },
    personalreport:{
        title: "Laporan pribadi",
        profit: "Jumlah keuntungan",
        formula: "Formula perhitungan laba: jumlah kemenangan-jumlah tugas",
        task: "Jumlah tugas",
        recharge: "Jumlah Top Up",
        withdrawal: "Jumlah penarikan tunai",
        win_money: "Jumlah kemenangan",

    },
    service: {
        title: "Layanan pelanggan online",
        relation: "Hubungi",
        hint: "Kami berdedikasi untuk melayani Anda 24 jam sehari, 7 * 24 jam sehari.",

    },
    msg:{
        "次": "Kali",
        "用户不存在！": "Pengguna tidak ada!",
        "密码错误！": "Sandi salah!",
        "登录成功！": "Log masuk berhasil!",
        "邀请码不存在！": "Kode undangan tidak ada!",
        "用户名位数错误！": "Bit nama pengguna salah!",
        "密码位数错误！": "Nomor kata sandi salah!",
        "用户名不能存在中文！": "Nama pengguna tidak boleh ada dalam bahasa Cina!",
        "邀请码不能为空！": "Kode undangan tidak boleh kosong!",
        "注册成功！": "Pendaftaran berhasil!",
        "注册失败！": "Pendaftaran gagal!",
        "用户已存在": "Pengguna telah ada",
        "刷新成功！": "Penyegaran berhasil!",
        "请联系顾问或接待员": "Silakan hubungi konsultan atau resepsionis",
        "金额错误！": "Jumlahnya salah!",
        "请选号！": "Silakan pilih nomornya!",
        "请填写金额!": "Silakan isi jumlahnya!",
        "余额不足，请联系客服充值！": "Saldo tidak mencukupi, silakan hubungi layanan pelanggan untuk mengisi ulang!",
        "没有更多了": "Tidak ada lagi",
        "没有数据": "Tidak ada data",
        "余额不足！": "Saldo tidak mencukupi!",
        "扣款失败！": "Pemotongan gagal!",
        "下注异常！": "Taruhan tidak normal!",
        "投注成功！": "Taruhan berhasil!",
        "参数异常！": "Parameter tidak normal!",
        "获取中": "_Dapatkan dalam...",
        "请完成任务单后进入": "Silakan lengkapi daftar tugas",
        "请联系客服充值": "Silakan hubungi layanan pelanggan untuk mengisi ulang",
        "请设置收款卡!": "Silakan atur kartu tanda terima!",
        "功能已禁用!": "Fungsi dinonaktifkan!",
        "账号下线": "Akun offline",
        "登录/注册": "Login/registrasi",
        "登录可享受更多服务！": "Masuk untuk menikmati lebih banyak layanan!",
        "未设置": "Tidak disetel",
        "已设置": "Telah ditetapkan",
        "提交": "Commit",
        "确定": "Untuk menentukan",
        "请填写完整": "Silakan isi selengkapnya",
        "两次密码不一致！": "Dua kata sandi tidak konsisten!",
        "设置提现密码成功！": "Atur kata sandi penarikan dengan sukses!",
        "提现密码已设置，需要修改请联系客服": "Kata sandi penarikan telah ditetapkan, silakan hubungi layanan pelanggan jika Anda perlu mengubahnya.",
        "已绑定": "Telah diikat",
        "无": "Tidak ada",
        "更换头像成功！": "Avatar pengganti berhasil!",
        "更新头像失败！": "Gagal memperbarui avatar!",
        "请勿重复设置！": "Jangan ulangi pengaturannya!",
        "设置姓名成功！": "Set nama berhasil!",
        "设置姓名失败！": "Gagal mengatur nama!",
        "设置性别成功！": "Atur kesuksesan gender!",
        "设置性别失败！": "Pengaturan gender gagal!",
        "请设置姓名后再绑定银行卡！": "Silakan atur nama Anda sebelum mengikat kartu bank Anda!",
        "请设置提现密码后再绑定银行卡！": "Silakan atur kata sandi penarikan sebelum mengikat kartu bank!",
        "确认绑卡": "Konfirmasi kartu terikat",
        "请输入银行卡号！": "Silakan masukkan bank!",
        "请输入银行名称！": "Silakan masukkan nama bank!",
        "设置银行卡成功！": "Siapkan kartu bank dengan sukses!",
        "设置银行卡失败！": "Pengaturan kartu bank gagal!",
        "旧密码错误": "Sandi lama salah",
        "修改密码成功！": "Ubah kata sandi dengan sukses!",
        "修改密码失败！": "Gagal mengubah kata sandi!",
        "请填写正确的金额": "Silakan isi jumlah yang benar",
        "提现金额不能少于：": "Jumlah penarikan tunai tidak boleh kurang dari:",
        "提现金额不能大于：": "Jumlah penarikan tunai tidak boleh lebih besar dari:",
        "当日提现次数已用完": "Penarikan tunai telah habis pada hari yang sama",
        "提现成功！": "Penarikan uang tunai berhasil!",
        "提现失败！": "Penarikan tunai gagal!",
        "金额": "Jumlah",
        "待审核": "Untuk ditinjau",
        "审核成功": "Ulasan berhasil",
        "审核退回": "Review Return",
        "请充值后观看视频！": "Silakan isi ulang dan tonton videonya!",
        "释放即可刷新": "Lepaskan untuk menyegarkan...",
        "加载中": "Memuat dalam...",
        "下拉即可刷新": "Tarik ke bawah untuk menyegarkan...",
    },
    setLang:{
        title: "Atur Bahasa"
    },
    profile: {

        portrait: "Fotografi",
        video: "Video",
        jianjie: "Profil Pribadi",
        liji: "Buat janji sekarang.",
        chongdan: "Rentang pengisian daya",
        fd: "Wanita terhormat.",
        yuyue: "Janji temu",
        archives: "Berkas saudari.",
        age: "Usia",
        height: "Tinggi badan",
        weight: "Berat badan",
        cup: "CUP",
        city: "Kota",
        area: "Area layanan",
        item: "Proyek layanan"
    },
    lang:{
        zh: "Cina",
        jp: "Jepang",
        ara: "Bahasa",
        en: "Bahasa Inggris",
        spa: "Spanyol",
        it: "Italia",
        de: "Jerman",
        pt: "Portugis",
        fra: "Perancis",
        nl: "Belanda",
        id: "Bahasa Indonesia",
        hi: "Bahasa Hindi",
        kor: "Bahasa Korea",
        tr: "Turki",
        swe: "Swedia",
        ga: "Irlandia",
        ru: "Rusia",
        vie: "Vietnam",
        th: "Bahasa Thailand",
        est: "Estonia",
        be: "Belarusia",
        bul: "Bulgaria",
        pl: "Bahasa Polandia",
        ms: "Bahasa Melayu",
    }
}
export default Id