<template>
  <div class="convention-hall page">
    <!--    <div  class="nav-bar newnavbar van-nav-bar van-hairline&#45;&#45;bottom">
          <div class="van-nav-bar__content">
            <div class="van-nav-bar__left">
              <span  style="font-size: 16px; color: rgb(255, 255, 255); display: none;">選ぶ</span>
            </div>
            <div class="van-nav-bar__title van-ellipsis">
              <div  class="menu-item-icon van-image" style="height: 40px; margin: 10px 0px 0px;">
                <img :src="baseInfo.ico" class="van-image__img">
              </div>
            </div>
            <div class="van-nav-bar__right">
             </div>
          </div>
        </div>-->

    <van-nav-bar class="nav-bar" :title="$t('choose.title')">
      <template #right>
        <van-image @click="toService" style="width: 32px;" src="./img/kefu.png"></van-image>
      </template>
    </van-nav-bar>
    <div class="banner" v-if="xuanfeiguanggao != ''">
      <div class="van-swipe">
        <div class="van-swipe__track" style="transition-duration: 0ms; transform: translateX(0px); ">
          <div class="van-swipe-item">
            <div class="van-image" style="width: 100%; ">
              <img class="van-image__img" :src="xuanfeiguanggao" style="object-fit: cover;">
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-tabs v-model="active" animated swipeable @change="OnChange">
      <van-tab v-for="(v,key) in addlist" :key="key" :title="v.name" :name="v.key"></van-tab>
    </van-tabs>

    <!--    <div class="list" style="padding-bottom: 40.667vw">
          <div class="pblBox">
            <div  style="width: calc(50% - 5px);"  v-for="(v,key) in datalist" :key="key" @click="profile(v.id)">
              <div class="releaseItem">
                <div class="imgBox">
                  <div class="van-image" style="width: 100%; height: 100%;">
                    <img :src="v.img_url" class="van-image__img">
                  </div>
                  <span >
                      <i >{{$t("profile.fd")}}</i>
                    </span>
                  <div  class="infoBox">
                    <i  class="van-icon van-icon-location" style="font-size: 14px;"></i>
                    <span style="font-size: 12px; margin: 0px 0px 0px 2px;">{{ v.address }}</span>
                    <div  class="wantyuebtn">{{ $t("profile.yuyue") }}</div>
                  </div>
                </div>
                <div  class="tagBox" style="color: rgb(102, 102, 102);">{{ v.flag[0] }}</div>
                <div  class="di">
                  <div >
                    <span  style="display: inline-block; margin-right: 2px; width: 12px; height: 12px; border-radius: 50%; background: rgb(239, 186, 143);"></span>
                    <span >{{ v.xuanfei_name }}</span></div>
                  <div ><i  class="van-icon van-icon-like" style="color: rgb(220, 99, 161);"></i>
                    <span data-v-b46fb340="">{{ v.dianzan }}</span>
                  </div>
                </div>
              </div>
            </div>
    
          </div>-->

    <div class="movie_list_1">
      <div class="movie-list-item"  v-for="(v,key1) in datalist" :key="key1" @click="profile(v.id)">
        <div class="horizontalItem">
          <div class="imgBox">
            <div class="van-image"
                 style="width: 120px; height: 120px; overflow: hidden; border-radius: 8px;">

              <img :src="v.img_url" class="van-image__img"
                   style="object-fit: cover;">
            </div>
            <span><i >{{$t("profile.fd")}}</i></span>
          </div>
          <div style="width: calc(100% - 140px);">
            <div class="top">
              <div>
                <div class="van-image" style="width: 13px;">
                  <img src="img/home/vip.png"  class="van-image__img"></div>
                <span>{{$t("choose.txt1")}}</span></div>
              <div>
                <div class="van-image" style="width: 13px;">
                  <img src="img/home/vip.png" class="van-image__img">
                </div>
                <span>{{$t("choose.txt2")}}</span></div>
              <div> {{ v.xuanfei_name }}</div>
            </div>
            <div class="tags">

              <span  v-for="(item,keys) in v.flag" :key="keys" :class="{'van-tag--large van-tag van-tag--default':1,'van-tag--large':keys==0,'van-tag--success':keys==1,'van-tag--danger':keys==2,'van-tag--warning':keys==3}" >{{item}}</span>

            </div>
            <div style="display: flex; align-items: center;">
              <span style="font-size: 14px; color: rgb(102, 102, 102);">{{$t("choose.txt4")}}：</span>
              <div tabindex="0" role="radiogroup" class="van-rate">
                <div role="radio" tabindex="0" aria-setsize="5" aria-posinset="1"
                     class="van-rate__item" aria-checked="true"><i
                                                                   class="van-icon van-icon-star van-rate__icon van-rate__icon--full"
                                                                   style="font-size: 18px;"></i></div>
                <div role="radio" tabindex="0" aria-setsize="5" aria-posinset="2"
                     class="van-rate__item" aria-checked="true"><i
                                                                   class="van-icon van-icon-star van-rate__icon van-rate__icon--full"
                                                                   style="font-size: 18px;"></i></div>
                <div role="radio" tabindex="0" aria-setsize="5" aria-posinset="3"
                     class="van-rate__item" aria-checked="true"><i
                                                                   class="van-icon van-icon-star van-rate__icon van-rate__icon--full"
                                                                   style="font-size: 18px;"></i></div>
                <div role="radio" tabindex="0" aria-setsize="5" aria-posinset="4"
                     class="van-rate__item" aria-checked="true"><i
                                                                   class="van-icon van-icon-star van-rate__icon van-rate__icon--full"
                                                                   style="font-size: 18px;"></i></div>
                <div role="radio" tabindex="0" aria-setsize="5" aria-posinset="5"
                     class="van-rate__item" aria-checked="true"><i
                                                                   class="van-icon van-icon-star van-rate__icon van-rate__icon--full"
                                                                   style="font-size: 18px;"></i></div>
              </div>
            </div>
            <div style="color: rgb(153, 153, 153); font-size: 12px; margin: 5px 0px;">
              {{$t("profile.height")}}:{{ v.height }} {{ $t("profile.cup") }}：{{v.cup}} {{$t("choose.txt5")}}
            </div>
            <div class="yueta">{{$t("choose.txt3")}}</div>
          </div>
        </div>
      </div>

      <div class="van-empty" style="width: 100%; padding-top: 90px; color: rgb(0, 0, 0);" v-if="datalist.length == 0">
        <div class="van-empty__image" style="width: 50px; height: 50px;">
          <img src="/img/emptyData.png"></div>
        <p class="van-empty__description">{{ $t('msg.没有更多了') }}</p>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      xuanfeiguanggao: '',
      active: 0,
      datalist: [],
      addlist: [],
      videoSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup: 1,
      },
      baseInfo: []
    };
  },
  methods: {
    getAddress() {
      this.$http({
        method: 'get',
        url: 'address_list'
      }).then(res => {
        this.addlist = res.data;
      });
    },

    toService(){
      this.$router.push("ServiceOnline");
    },
    profile(id) {
      this.$router.push({path: '/profile?id=' + id});
    },
    OnChange() {
      this.videolist = [];
      this.number = 0;
      this.page = 0;
      this.count = 0;
      this.getVideoList();//获取视频列表
    },

    itemChange() {
      this.active = this.$refs.swiper.swiper.activeIndex
      this.OnChange()
    },
    getVideoList() {
      this.$http({
        method: 'get',
        url: 'xuanfeilist',
        data: {id: this.active}
      }).then(res => {
        this.datalist = res.data.list;
      });
    },

    getChooseItem() {
      this.$http({
        method: 'get',
        url: 'base_choose'
      }).then(res => {
        if (res.code == 200) {
          this.xuanfeiguanggao = res.data.xuanfeiguanggao;
        }
      })
    },

    getBaseInfo() {
      this.$http({
        method: 'get',
        url: 'base_info'
      }).then(res => {

        this.baseInfo = res.data;
      })
    }
  },
  created() {
    this.getChooseItem();
    this.getAddress();
    this.OnChange();
    this.getBaseInfo();
  }
};
</script>

<style lang="less" scoped>
.releaseItem .tagBox {
  font-size: 3vw;
  margin: 5px 0;
}

.releaseItem .di {
  display: flex;
  justify-content: space-between;
  font-size: 3vw;
  color: #666;
}

.releaseItem .imgBox .infoBox {
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, .4);
  display: flex;
  align-items: center;
  color: #fff;
  width: 100%;
  height: 8vw;
  padding: 0 10px;
}

.releaseItem .imgBox .infoBox .wantyuebtn {
  border: 1px solid #fff;
  padding: 5px 8px;
  border-radius: 8px;
  opacity: .9;
  font-size: 3vw;
  margin-left: auto;
}

.releaseItem .imgBox > span {
  position: absolute;
  z-index: 9;
  background-color: #ebcaaf;
  color: #8d684b;
  transform: rotate(45deg);
  width: 20vw;
  height: 20vw;
  top: -11vw;
  right: -11vw;
}

.releaseItem .imgBox > span > i {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 700;
  font-size: 4vw;
}

.van-image__img {
  display: block;
  width: 100%;
  height: 100%;
}

.van-image {
  position: relative;
  display: inline-block;
}

.imgBox {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  /*height: 67vw;*/
}

.releaseItem {
  width: 100%;
  margin-bottom: 15px;
}

.pblBox {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  flex-wrap: wrap;
}

.van-col {
  padding: 2.333vw;
  float: none;
}

.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
}

.nav-bar {
  background: linear-gradient(90deg, #7e5678, #e6c3a1);
  height: 100px;
}

.van-nav-bar {
  line-height: 50px;
}

::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 35px;
}

::v-deep .van-nav-bar__content {
  height: 100px;
}

.van-sidebar {
  width: 180px;
}

.convention-hall {
  display: flex;
  flex-direction: column;
  bottom: 20px;
  background: #f2f2f5;
}

::v-deep .van-tab {
  font-size: 30px;
  line-height: 100px;
  font-weight: bold;
}

::v-deep .van-tab--active {
  border-bottom: 0;
}

::v-deep .van-tabs--line .van-tabs__wrap {
  height: 100px;
}

::v-deep .van-tabs__wrap--scrollable .van-tab {
  padding: 0 23px;
}

.card {
  background-color: #8a637d;
  padding: 0.625rem;
  width: 95%;
  color: white;
  font-size: 0.8125rem;
  margin: 0.625rem auto;
  border-radius: 0.375rem;
}

::v-deep .van-row--flex {
  height: 80px;
  line-height: 80px;
}

.rig-box {
  width: 95%;
  margin: 0.625rem auto;
}

.rig-title {
  color: #0bdab0;
  font-size: 1.125rem;
}

.rig-content {
  font-size: 20px;
  // margin-top: 10px;
}

.address {
  width: 90%;
  margin: 0 auto;
}
.horizontalItem {
  display: flex;
  border-bottom: 1px solid #eee;
  padding: 2.667vw 0 4vw;
}
.horizontalItem .imgBox{
  position: relative;
  overflow: hidden;
  border-radius: 1.067vw;
  margin-right: 2.667vw;
}
.horizontalItem .top {
  display: flex;
}

.horizontalItem .top>div:first-child, .horizontalItem .top>div:nth-child(2) {
  background: linear-gradient(180deg, #e7caaf, #fff7ed);
  color: #a4826b;
  font-weight: 600;
  font-size: 3vw;
  padding: .4vw;
  margin-right: 1.333vw;
}
.horizontalItem .tags {
  display: flex;
  margin: .667vw 0 .667vw;
  flex-wrap: wrap;
}
.horizontalItem .tags>.van-tag {
  padding: .667vw;
  font-size: 3.333vw;
  margin: 0 .667vw .667vw 0;
  border-radius: 1.067vw;
  line-height: 4vw;
}
.horizontalItem .yueta {
  background: linear-gradient(90deg, #df35ad, #4f1db5);
  color: #fff;
  border-radius: 1.067vw;
  padding: 1.333vw 0;
  text-align: center;
  font-size: 4vw;
}
.horizontalItem .imgBox>span {
  position: absolute;
  z-index: 9;
  background-color: #ebcaaf;
  color: #8d684b;
  transform: rotate(45deg);
  width: 20vw;
  height: 20vw;
  top: -11vw;
  right: -11vw;
}
.horizontalItem .imgBox>span>i {
  position: absolute;
  bottom: .667vw;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 700;
  font-size: 4vw;
}
</style>
